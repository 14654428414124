<template>
  <not-authenticated>
    <v-card
      class="elevation-0 mx-auto login-area-box"
      color="transparent"
      flat>
      <h1 class="subtitle-1 mediumGray--text text-center mb-6">
        Acessar plataforma
      </h1>
    </v-card>
  </not-authenticated>
</template>

<script>
  import NotAuthenticated from '@/components/NotAuthenticated'

  export default {
    name: 'Login',
    components: {
      NotAuthenticated
    },
    beforeMount () {
      this.$store.commit('app/setLoading', true)
      // /auth?token=123 or /auth/123
      const token = this.$route.query.token || this.$route.params.pathMatch.slice(1) // remove first /
      this
        .$store
        .dispatch('user/auth', token)
        .then(access => {
          this.$router.push({ name: 'CaseChat', params: { caseId: access.caseId } })
          this.$store.commit('app/setLoading', true)
        })
        .catch((error) => {
          this.errorLogin(error)
          this.isLoading = false
          this.validLogin = !this.isLoading
        })
    },
    methods: {
      errorLogin (message) {
        this.$root.$emit('snackbar', {show: true, color: 'error', text: 'Erro no Login, confira seus dados'})
      },
      _setUserToRaven (user) {
        // Confirmar se o groups está passando
        // Raven.setUserContext({
        //   email: user.email,
        //   id: user.id,
        //   groups: user.groups
        // })
      }
    }
  }
</script>
