<template>
  <not-authenticated>
    <v-card
      class="elevation-0 mx-auto login-area-box"
      color="transparent"
      flat>
      <h1 class="subtitle-1 mediumGray--text text-center mb-6">
        Recuperar sua senha
      </h1>
      <v-card-text class="pt-0">
        <v-form
          ref="formPass"
          v-model="validPass"
          lazy-validation>
          <v-text-field
            v-model="user.email"
            name="mlogin2"
            label="Seu e-mail"
            type="text"
            :rules="emailRules"
            required />
        </v-form>
      </v-card-text>
      <v-card-actions class="px-3">
        <router-link
          class="mediumGray--text caption"
          :to="{ name: 'Login' }">
          ir para o login
        </router-link>
        <v-spacer />
        <v-btn
          color="primary"
          depressed
          :disabled="!validPass"
          @click="resetPassSubmit">
          Recuperar
        </v-btn>
      </v-card-actions>
    </v-card>
  </not-authenticated>
</template>

<script>
  import NotAuthenticated from '@/components/NotAuthenticated'
  import validate from '@/mixins/validate'

  export default {
    name: 'ForgotPassword',
    components: {
      NotAuthenticated
    },
    mixins: [ validate ],
    data () {
      return {
        validPass: true,
        user: {
          email: ''
        }
      }
    },
    methods: {
      resetPassSubmit () {
        if (this.$refs.formPass.validate()) {
          return this.$store
            .dispatch('user/resetPassword', this.user.email)
            .then(this._success)
            .catch(this._failure)
        }
      },
      _success () {
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Um email com as intruções para recuperar a senha foi encaminhada para o endereço'})
      },
      _failure (message) {
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'houve um problema'})
      }
    }
  }
</script>
