<template>
  <template-simple>
    <div id="boleto" />
  </template-simple>
</template>

<script>
  import TemplateSimple from '@/components/TemplateSimple'
  import BoletoBancario from './boleto-bancario'
  require('./boleto-bancario.css')

  export default {
    components: { TemplateSimple },
    beforeMount () {
      this.$store.dispatch('bankSlips/get', this.$route.params.hash).then(ticketPayment => {

        const dadosBoleto = {
          convenio: ticketPayment.covenant,
          carteira: ticketPayment.wallet,
          sequencial: ticketPayment.sequential,
          numDoc: ticketPayment.numDoc,
          dataEmissao: this.$d(ticketPayment.insertedAt.toDate(), 'short_date'),
          dataVencimento: this.$d(ticketPayment.dueAt.toDate(), 'short_date'),
          // dataVencimento: '16/11/2019',
          valor: ticketPayment.amount,
          ...ticketPayment.instructions
        }

        const boleto = new BoletoBancario({
          cedente: ticketPayment.assignator,
          pagador: ticketPayment.payer,
          boleto: dadosBoleto
        })

        boleto.render('#boleto')
      })
    },
    methods: {
      _failure () {
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'Boleto não encontrado'})
      }
    }
  }
</script>
