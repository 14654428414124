<template>
  <div>
    <v-app-bar
      class="elevation-0 pr-2"
      color="primary"
      height="50px"
      clipped-right
      fixed>
      <div class="logo-topbar ml-2">
        <h1>
          <img
            alt="Logo Modera"
            src="@/assets/images/logo-modera-white.svg"
            class="logo">
        </h1>
      </div>
    </v-app-bar>
    <v-container class="container-page template-simple__container">
      <v-card tile>
        <v-card-text>
          <v-container>
            <slot />
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  export default {
  }
</script>

<style lang="sass">
  .template-simple__container
    .v-card__text
      background: #fff

    .logo-topbar
      display: flex
      align-items: center

      h1
        display: flex
        margin-right: 10px

    .logo
      width: 115px
</style>
